import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  user_partner_id: number;
  user_partner_first_name: string;
  user_partner_last_name: string;
  user_partner_image: string;
  user_partner_district: Array<UserPartnerDistrict>;
  user_partner_province: UserPartnerProvince;
  token: Token;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

export interface Token {
  token_access: string;
  token_refresh: string;
  token_user_partner_id: number;
}

export interface UserPartnerDistrict {
  district_id: number;
  district_name_en: string;
  district_name_th: string;
}

export interface UserPartnerProvince {
  province_id: number;
  province_name_th: string;
  province_name_en: string;
}
@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {
    user_partner_province: {
      province_name_en: "",
    },
  } as User;
  isAuthenticated = !!JwtService.getTokenAccToken();
  userCreditWallet = 0;
  interValCreditWallet;
  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_INTERVAL_CREDIT_WALLET](interval) {
    this.interValCreditWallet = interval;
  }

  @Mutation
  [Mutations.CLEAR_INTERVAL_CREDIT_WALLET]() {
    clearInterval(this.interValCreditWallet);
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user: User) {
    this.isAuthenticated = true;
    this.errors = [];
    window.localStorage.setItem(
      "user_province",
      user.user_partner_province.province_name_en
    );
    window.localStorage.setItem(
      "user_district",
      JSON.stringify(user.user_partner_district)
    );
    window.localStorage.setItem(
      "user_partner_id",
      user.user_partner_id.toString()
    );
    window.localStorage.setItem(
      "user_first_name",
      user.user_partner_first_name
    );
    window.localStorage.setItem("user_last_name", user.user_partner_last_name);
    window.localStorage.setItem("user_image", user.user_partner_image);

    JwtService.saveAccToken(user.token.token_access);
    JwtService.saveRereshToken(user.token.token_refresh);
  }

  @Mutation
  [Mutations.SET_REFRESH](payload) {
    JwtService.saveAccToken(payload.token.token_access);
    JwtService.saveRereshToken(payload.token.token_refresh);
  }
  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD]() {
    // this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyAccToken();
    JwtService.destroyRefreshToken();
  }

  @Mutation
  [Mutations.SET_USER_CREDIT_WALLET](credit) {
    this.userCreditWallet = credit;
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    return ApiService.post("/api/v1/partner/login", params.params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.data);
        return true;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.data);
        return false;
      });
  }

  @Action
  [Actions.REFRESH_TOKEN](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    return ApiService.post("/api/v1/partner/refresh_token", params.params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REFRESH, data.data);
        return data;
      })
      .catch(({ response }) => {
        return response;
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
      params: {
        ...payload,
      },
    };
    return ApiService.query("forgot_password", params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getTokenAccToken()) {
      const params = {
        params: {
          token: JwtService.getTokenAccToken(),
        },
      };
      ApiService.query("verify_token", params as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.FETCH_USER_INFO]() {
    return ApiService.get(`/api/v1/partner`, "get_user_partner")
      .then(({ data }) => {
        if (data.msg == false) return;
        this.context.commit(
          Mutations.SET_USER_CREDIT_WALLET,
          data.data.user_partner_we_credit
        );
        return;
      })
      .catch(({ response }) => {
        console.log(response);
        return;
      });
  }
}
