import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ReportModule from "@/store/modules/ReportModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import RiderModule from "@/store/modules/RiderModule";
import WalletModule from "@/store/modules/WalletModule";
import BillModule from "@/store/modules/InvoiceModule";
import BankModule from "@/store/modules/BankModule";
import WalletWithdraw from "@/store/modules/WalletWithdraw";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ReportModule,
    RiderModule,
    WalletModule,
    BillModule,
    BankModule,
    WalletWithdraw,
  },
  state: {
    isLoadingGlobal: false,
  },
  mutations: {
    setLoadingGlobal(state, status) {
      state.isLoadingGlobal = status;
    },
  },
});

export default store;
