import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";

export interface WalletModel {
  backApprove: Array<backApprove>;
  calWithdraw: calWithdraw;
  withdrawdetail: calWithdraw;
}

interface backApprove {
  bank_manager_id: number;
  bank_manager_bank: bank_manager_bank;
  bank_manager_bank_number: string;
  bank_manager_customer_name: string;
  bank_manager_default_bank: number;
}
interface bank_manager_bank {
  bank_id: number;
  bank_code: string;
  bank_name_th: string;
  bank_name_en: string;
  bank_icon: string;
}
interface calWithdraw {
  bank_weserve: bank_weserve;
  bank_manager: bank_manager;
  withdraw_price: number;
  commission_price: number;
  total_price: number;
  status: number;
  remark: string;
}
interface bank_weserve {
  bank_weserve_id: number;
  bank_weserve_bank: bank_weserve_bank;
  bank_weserve_account_name: string;
  bank_weserve_account_number: string;
}
interface bank_weserve_bank {
  bank_id: number;
  bank_code: string;
  bank_name_th: number;
  bank_name_en: number;
  bank_icon: string;
}
interface bank_manager {
  bank_manager_id: number;
  bank_manager_bank: bank_manager_bank;
  bank_manager_bank_number: string;
  bank_manager_customer_name: string;
}
interface bank_manager_bank {
  bank_id: number;
  bank_code: string;
  bank_name_th: string;
  bank_name_en: string;
  bank_icon: string;
}
@Module({ namespaced: true })
export default class WalletModule extends VuexModule implements WalletModel {
  backApprove = [] as Array<backApprove>;
  calWithdraw = {
    bank_weserve: { bank_weserve_bank: {} },
    bank_manager: { bank_manager_bank: {} },
  } as calWithdraw;
  withdrawdetail = {
    bank_weserve: { bank_weserve_bank: {} },
    bank_manager: { bank_manager_bank: {} },
  } as calWithdraw;

  @Mutation
  [Mutations.SET_WALLET_BANK_LIST](data: backApprove[]) {
    this.backApprove = data;
  }
  @Mutation
  [Mutations.SET_WITHDRAW](data: calWithdraw) {
    this.calWithdraw = data;
  }
  @Mutation
  [Mutations.SET_WITHDRAW_DETAIL](data: calWithdraw) {
    this.withdrawdetail = data;
  }
  @Action
  [Actions.FETCH_BANK_APPROVE]() {
    return (
      ApiService.query(`/api/v1/partner/get_bank_approve`, {})
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier

          this.context.commit(Mutations.SET_WALLET_BANK_LIST, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }

  @Action
  [Actions.FETCH_BANK_DETAIL](id) {
    return (
      ApiService.get(`/api/v1/partner/get_transaction_request_cash`, id)
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier

          this.context.commit(Mutations.SET_WITHDRAW_DETAIL, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }

  @Action
  [Actions.CAL_WITHDRAW_WALLET]({ form }) {
    const payload = {
      bank_manager_id: form.bank_manager_id,
      withdraw_price: form.withdraw_price,
    };
    return (
      ApiService.post(
        `/api/v1/partner/calculate_withdraw`,
        payload as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_WITHDRAW, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }

  @Action
  [Actions.CREATE_WITHDRAW]({ form }) {
    const payload = {
      bank_manager_id: form.bank_manager_id,
      withdraw_price: form.withdraw_price,
      commission_price: form.commission_price,
      total_price: form.total_price,
    };
    return (
      ApiService.post(
        `/api/v1/partner/create_withdraw`,
        payload as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
}
