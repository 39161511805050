import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { DistrictItem } from "./LocationModule";
import { AxiosRequestConfig } from "axios";

export interface Bill {
  billList: Array<billInfo>;
  setbill: Array<billInfo>;
  setbillid: billInfo;
}

export interface billInfo {
  create_at: string;
  bill_id: number;
  users_balance: number;
  users_district: DistrictItem[];
  users_email: string;
  users_id: string;
  users_name: string;
  users_phone: string;
  users_status: number;
  bill_due_date: number;
  bill_number: string;
  bill_description: string;
  bill_net_price: number;
  bill_status: number;
  data: number;
  check: string;
  bill_type: BillType;
}

interface BillType {
  bill_type_id: number;
  bill_type_name: string;
}
export interface PayBill {
  bill_complete: PayBillInfo[];
  bill_fail: PayBillInfo[];
}
export interface PayBillInfo {
  bill_district_id: number;
  bill_id: number;
  bill_net_price: number;
  bill_user_partner_id: number;
}

export interface selection {
  name: string;
  value: number;
}
export interface bill {
  data: number;
}
@Module({ namespaced: true })
export default class BillModule extends VuexModule implements Bill {
  billList = [] as Array<billInfo>;
  setbill = [] as Array<billInfo>;
  setbillid = {
    create_at: "",
    bill_id: 0,
    users_balance: 0,
    users_district: [] as DistrictItem[],
    users_email: "",
    users_id: "",
    users_name: "",
    users_phone: "",
    users_status: 0,
    bill_due_date: 0,
    bill_number: "",
    bill_description: "",
    bill_net_price: 0,
    bill_status: 0,
    data: 0,
    check: "",
    bill_type: {},
  } as billInfo;

  setbillpay = [] as Array<billInfo>;

  setbillpaycheck = {
    bill_complete: [] as Array<PayBillInfo>,
    bill_fail: [] as Array<PayBillInfo>,
  } as PayBill;

  filterBillListForm = {
    bill_status: -1,
    bill_text: "",
  };

  get getBillSelection(): Array<selection> {
    return this.billList.map((val) => {
      return { name: val.users_name, value: val.bill_id };
    });
  }

  get getBillList(): Array<billInfo> {
    return this.billList.map((val, index) => {
      return { no: index + 1, ...val };
    });
  }

  @Mutation
  [Mutations.SET_BILL_LIST](data: billInfo[]) {
    this.billList = data;
  }
  @Mutation
  [Mutations.SET_COUNT_BILL](data: billInfo[]) {
    this.setbill = data;
  }
  @Mutation
  [Mutations.SET_BILL_ID_LIST](data: billInfo) {
    this.setbillid = data;
    console.log(this.setbillid);
  }
  @Mutation
  [Mutations.SET_BILL_PAY_LIST](data: billInfo[]) {
    this.setbillpay = data;
  }
  @Mutation
  [Mutations.BILL_PAY_CHECK](data: PayBill) {
    this.setbillpaycheck = data;
  }
  @Mutation
  [Mutations.BILL_PAY](billId: number) {
    console.log(billId);
    console.log(this.billList);

    const index = this.billList.findIndex((bill) => bill.bill_id === billId);
    console.log(index);

    this.billList.splice(index, 1);
  }
  @Action
  [Actions.FETCH_BILL_LIST]() {
    //ชื่อ fucntion
    return (
      ApiService.query(`/api/v1/partner/get_bill_manager`, {})
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_BILL_LIST, data.data);
          console.log(data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }
  @Action
  [Actions.TOPUP_CREDIT_PAY]({ transactionPay }) {
    const payload_new = {
      bills: transactionPay,
    };
    console.log("ทดสอบ555");
    return (
      ApiService.post(
        `api/v1/partner/pay_bill_manager`,
        payload_new as AxiosRequestConfig
      )
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.BILL_PAY_CHECK, data.data);

          if (data.data.bill_complete.length > 0) {
            this.context.commit(Mutations.BILL_PAY, transactionPay[0].bill_id);
          }

          // console.log("บิล");
          // console.log(data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.FETCH_BILL_BY_ID]({ transactionId }) {
    return (
      ApiService.get(`api/v1/partner/get_bill_manager`, transactionId)
        .then(({ data }) => {
          if (data.msg == false) return false;
          this.context.commit(Mutations.SET_BILL_ID_LIST, data.data);
          // eslint-disable-next-line prettier/prettier
          //console.log(data.data);
          console.log(data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  @Action
  [Actions.FETCH_COUNT_BILL]() {
    //ชื่อ fucntion
    return (
      ApiService.query(`/api/v1/partner/get_count_bill_manager`, {})
        .then(({ data }) => {
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_COUNT_BILL, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }
}
