import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { DistrictItem } from "./LocationModule";

export interface Riders {
  riderList: Array<riderInfo>;
}

export interface riderInfo {
  create_at: string;
  id: number;
  users_balance: number;
  users_district: DistrictItem[];
  users_email: string;
  users_id: string;
  users_name: string;
  users_phone: string;
  users_status: number;
}

export interface selection {
  name: string;
  value: number;
}

@Module({ namespaced: true })
export default class RiderModule extends VuexModule implements Riders {
  riderList = [] as Array<riderInfo>;
  filterRiderListForm = {
    users_status: -1,
    search_text: "",
  };

  get getRiderListSelection(): Array<selection> {
    return this.riderList.map((val) => {
      return { name: val.users_name, value: val.id };
    });
  }

  get getRiderList(): Array<riderInfo> {
    return this.riderList.map((val, index) => {
      return { no: index + 1, ...val };
    });
  }

  @Mutation
  [Mutations.SET_RIDER_LIST](data: riderInfo[]) {
    this.riderList = data;
  }

  @Action
  [Actions.FETCH_RIDER_LIST]() {
    return (
      ApiService.query(`/api/v1/partner/get_monitor_rider`, {})
        .then(({ data }) => {
          //console.log(data);
          if (data.msg == false) return [];
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_RIDER_LIST, data.data);
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return [];
        })
    );
  }

  @Action
  [Actions.FETCH_RIDER_BY_ID](riderId) {
    return (
      ApiService.get(`/api/v1/partner/get_rider_information`, riderId)
        .then(({ data }) => {
          if (data.msg == false) return false;
          // eslint-disable-next-line prettier/prettier
          return data.data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }
  // @Action
  // [Actions.TOPUP_CREDIT_BILL](amount) {
  //   const payload = {
  //     transaction_partner_we_credit: amount,
  //     transaction_partner_type: "Top Up",
  //     gb_pay_provider: "manager",
  //   };
  //   return (
  //     ApiService.post(
  //       `api/v1/partner/pay_bill_manager`,
  //       payload as AxiosRequestConfig
  //     )
  //       .then(({ data }) => {
  //         if (data.msg == false) return false;
  //         // eslint-disable-next-line prettier/prettier
  //         return data.data;
  //       })
  //       // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //       .catch(({ response }) => {
  //         return false;
  //       })
  //   );
  // }
}
