const ID_ACCTOKEN_KEY = "id_acctoken" as string;
const ID_REFRESHTOKEN_KEY = "id_refresh" as string;
/**
 * @description get token form localStorage
 */
export const getTokenAccToken = (): string | null => {
  return window.localStorage.getItem(ID_ACCTOKEN_KEY);
};

export const getTokenRefreshToken = (): string | null => {
  return window.localStorage.getItem(ID_REFRESHTOKEN_KEY);
};
/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveAccToken = (token: string): void => {
  window.localStorage.setItem(ID_ACCTOKEN_KEY, token);
};

export const saveRereshToken = (token: string): void => {
  window.localStorage.setItem(ID_REFRESHTOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyAccToken = (): void => {
  window.localStorage.removeItem(ID_ACCTOKEN_KEY);
};

export const destroyRefreshToken = (): void => {
  window.localStorage.removeItem(ID_REFRESHTOKEN_KEY);
};

export default {
  getTokenRefreshToken,
  getTokenAccToken,
  saveAccToken,
  saveRereshToken,
  destroyAccToken,
  destroyRefreshToken,
};
