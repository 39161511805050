import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "./../../store";
import { Actions } from "@/store/enums/StoreEnums";
import router from "./../../router";
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.interceptors.request.use(
      function (config) {
        const token = JwtService.getTokenAccToken();
        if (token) {
          config.headers.Authorization = token.toString();
        }
        config.headers.Accept = "application/json";
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );

    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        if (response.status == 401) {
          store.dispatch(Actions.LOGOUT);
          router.push({ name: "sign-in" });
        }
        return Promise.resolve(response);
      },
      async (error) => {
        if (error.response.status == 401) {
          const payload = {
            user_partner_id: window.localStorage.getItem("user_partner_id"),
            token_refresh: JwtService.getTokenRefreshToken(),
          };
          const res = await store.dispatch(Actions.REFRESH_TOKEN, payload);
          if (res.status >= 400) {
            store.dispatch(Actions.LOGOUT);
            router.push({ name: "sign-in" });
            return Promise.reject(error);
          } else {
            const originalRequestConfig = error.config;
            delete originalRequestConfig.headers.Authorization;
            return this.vueInstance.axios.request(originalRequestConfig);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    // ApiService.vueInstance.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getTokenAccToken()}`;
    // ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
    //   "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static delete(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(`${resource}/${slug}`);
  }
}

export default ApiService;
